// extracted by mini-css-extract-plugin
export var column = "UserProjects_List_Filters__column__YcmNN";
export var dropdownBody = "UserProjects_List_Filters__dropdownBody__eG158";
export var dropdownBodyHeading = "UserProjects_List_Filters__dropdownBodyHeading__NQR10";
export var dropdownBodyRow = "UserProjects_List_Filters__dropdownBodyRow__N0OfM";
export var dropdownTrigger = "UserProjects_List_Filters__dropdownTrigger__z9CdD";
export var flex = "UserProjects_List_Filters__flex___Sctn";
export var flexColumn = "UserProjects_List_Filters__flexColumn__ByGoa";
export var gap1 = "UserProjects_List_Filters__gap1__DfiJ0";
export var gap2 = "UserProjects_List_Filters__gap2__mnCx_";
export var gap3 = "UserProjects_List_Filters__gap3__idqPk";
export var gap4 = "UserProjects_List_Filters__gap4__LDOiY";
export var gap5 = "UserProjects_List_Filters__gap5__C0JZr";
export var label = "UserProjects_List_Filters__label__gXoGF";
export var row = "UserProjects_List_Filters__row__aGnni";
export var searchFieldInput = "UserProjects_List_Filters__searchFieldInput__jE1iM";
export var searchIcon = "UserProjects_List_Filters__searchIcon__NPFw4";