// extracted by mini-css-extract-plugin
export var buttonContainer = "UserProjects__buttonContainer__Yrfxj";
export var column = "UserProjects__column__TcN85";
export var container = "UserProjects__container__iej2w";
export var contentWrapper = "UserProjects__contentWrapper__Zifb_";
export var downloadIcon = "UserProjects__downloadIcon__R8j79";
export var filters = "UserProjects__filters__ABkgo";
export var flex = "UserProjects__flex__Tzxjh";
export var flexColumn = "UserProjects__flexColumn__QAYs8";
export var gap1 = "UserProjects__gap1__KKrDI";
export var gap2 = "UserProjects__gap2__IKTYP";
export var gap3 = "UserProjects__gap3__iFcuK";
export var gap4 = "UserProjects__gap4__vS4MX";
export var gap5 = "UserProjects__gap5__QZHgt";
export var heading = "UserProjects__heading__IKNNm";
export var newProjectMenu = "UserProjects__newProjectMenu__HzQHl";
export var newProjectMenuItem = "UserProjects__newProjectMenuItem__JZ0LG";
export var newProjectMenuItemControl = "UserProjects__newProjectMenuItemControl__oEA3l";
export var noResults = "UserProjects__noResults__MaaR5";
export var pagination = "UserProjects__pagination__NPeHV";
export var popoverBody = "UserProjects__popoverBody__AqCuu";
export var popoverRow = "UserProjects__popoverRow__lNyOf";
export var popoverTrigger = "UserProjects__popoverTrigger__luDdm";
export var projectsCount = "UserProjects__projectsCount__o7N1c";
export var row = "UserProjects__row__RFO5y";
export var sortAndCount = "UserProjects__sortAndCount__ZAmG3";
export var sortSelection = "UserProjects__sortSelection__V2DaQ";
export var title = "UserProjects__title__oX0rM";