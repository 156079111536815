// extracted by mini-css-extract-plugin
export var column = "UserProjects_List_Item__column__pIesK";
export var continueButton = "UserProjects_List_Item__continueButton__lAS4T";
export var deleteButton = "UserProjects_List_Item__deleteButton__mNDSS";
export var draftButtonsContainer = "UserProjects_List_Item__draftButtonsContainer__KN15M";
export var draftMessage = "UserProjects_List_Item__draftMessage__rGpRq";
export var draftProjectItem = "UserProjects_List_Item__draftProjectItem__eNXjJ";
export var finalizedMessage = "UserProjects_List_Item__finalizedMessage__zOqeo";
export var finalizedProjectItem = "UserProjects_List_Item__finalizedProjectItem__dU6tX";
export var flex = "UserProjects_List_Item__flex__nySym";
export var flexColumn = "UserProjects_List_Item__flexColumn__zPqFB";
export var gap1 = "UserProjects_List_Item__gap1__NhaHQ";
export var gap2 = "UserProjects_List_Item__gap2__c4mHr";
export var gap3 = "UserProjects_List_Item__gap3__S0l2Q";
export var gap4 = "UserProjects_List_Item__gap4__bibUB";
export var gap5 = "UserProjects_List_Item__gap5__MFiAZ";
export var inactiveMessage = "UserProjects_List_Item__inactiveMessage__Uzlfk";
export var inactiveProjectItem = "UserProjects_List_Item__inactiveProjectItem__Ewc62";
export var modalMessageButtons = "UserProjects_List_Item__modalMessageButtons__Xo9jX";
export var modalMessageContainer = "UserProjects_List_Item__modalMessageContainer__kDxgA";
export var modalMessageOverlay = "UserProjects_List_Item__modalMessageOverlay__QtJp9";
export var modalMessageText = "UserProjects_List_Item__modalMessageText__xo0Va";
export var productTag = "UserProjects_List_Item__productTag__Cyt1v";
export var projectDescription = "UserProjects_List_Item__projectDescription__FK_X2";
export var projectInfo = "UserProjects_List_Item__projectInfo__CmVPP";
export var projectItem = "UserProjects_List_Item__projectItem__WLZVx";
export var projectItemContainer = "UserProjects_List_Item__projectItemContainer__l7BNN";
export var projectLabel = "UserProjects_List_Item__projectLabel__ZqH84";
export var projectName = "UserProjects_List_Item__projectName__xMxql";
export var projectTitle = "UserProjects_List_Item__projectTitle__SbJXW";
export var projectUser = "UserProjects_List_Item__projectUser__u1e_D";
export var projectUserAvatar = "UserProjects_List_Item__projectUserAvatar__X0QSR";
export var projectUserText = "UserProjects_List_Item__projectUserText__pm52_";
export var proposalLabel = "UserProjects_List_Item__proposalLabel__w0UKh";
export var reviewMessage = "UserProjects_List_Item__reviewMessage__QkIAt";
export var reviewProjectItem = "UserProjects_List_Item__reviewProjectItem__G1Uxx";
export var row = "UserProjects_List_Item__row__tDGBM";